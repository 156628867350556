import React, {useState, useEffect} from "react";


export default function SMILESChemicalStructure({ chemicalStructureSVG }) {
    const [_chemicalStructureSVG, setChemicalStructureSVG] = useState('');

    useEffect(() => {
        setChemicalStructureSVG(chemicalStructureSVG);
    }, [chemicalStructureSVG])
    return <>
        {
            (_chemicalStructureSVG === undefined || _chemicalStructureSVG === "" || _chemicalStructureSVG === null)
            ? <div style={{paddingLeft: '12em'}}>Not Available</div>
            : <img style={{maxHeight: '450px', margin: '1em auto'}} className='chemical-structure-svg' src={`data:image/svg+xml;utf8,${encodeURIComponent(_chemicalStructureSVG)}`}/>
        }
    </>
}