import { SET_EXPERIMENTS, UPDATE_EXPERIMENT, SET_ACTIVE_EXPERIMENTS, UPDATE_ACTIVE_EXPERIMENT, REMOVE_ACTIVE_EXPERIMENT, SET_SEARCHED_EXPERIMENT, GET_ALL_TREATMENTS, GET_ALL_PROTEINS, GET_EXPERIMENT_COMPOUNDS } from './experimentsConstants'
import ApiService from '../apiService'

export const setAllTreatments = (allTreatments) => {
    return {
        type: GET_ALL_TREATMENTS,
        allTreatments: allTreatments
    }
}

export const setExperimentCompounds = (experimentCompounds) => {
    return {
        type: GET_EXPERIMENT_COMPOUNDS,
        experimentCompounds
    }
}

export const setAllProteins = (allProteins) => {
    return {
        type: GET_ALL_PROTEINS,
        allProteins
    }
}

export const setSearchedExperiment = (searchedExperiment) => {
    return {
        type: SET_SEARCHED_EXPERIMENT,
        searchedExperiment,
    }
}

export const setExperiments	 = (experiments) => {
    return {
        type: SET_EXPERIMENTS,
        experiments,
    }
}

export const updateExperiment = (experimentId, data) => {
    return {
        type: UPDATE_EXPERIMENT,
        experimentId,
        data,
    }
}

export const setActiveExperiments = (activeExperiments) => {
    return {
        type: SET_ACTIVE_EXPERIMENTS,
        activeExperiments,
    }
}

export const updateActiveExperiment = (activeExperimentId, activeData) => {
    return {
        type: UPDATE_ACTIVE_EXPERIMENT,
        activeExperimentId,
        activeData,
    }
}

export const removeActiveExperiment = (removeExperimentId) => {
    return {
        type: REMOVE_ACTIVE_EXPERIMENT,
        removeExperimentId,
    }
}

export const getAllExperiments = (token) => async dispatch => {
    return await ApiService.getExperiments(token)
        .then(getResponse => {
            const experiments = {}
            const active = {}

            if (getResponse) {
                getResponse.data.forEach(element => {
                    experiments[ element.id ] = element
                    if (element.active) {
                        active[ element.id ] = element
                    }
                })

                dispatch(setExperiments(experiments))
                dispatch(setActiveExperiments(active))
            }
        }).catch(console.warn);
}

export const updateExperimentRequest = (token, data) => async dispatch => {
    return await ApiService.updateExperiment(token, data)
        .then(updateResponse => {
            if (updateResponse) {
                dispatch(updateExperiment(updateResponse.data.id, updateResponse.data))

                if (data.active || updateResponse.data.active) {
                    dispatch(updateActiveExperiment(updateResponse.data.id, updateResponse.data))
                } else if (data.active === false) {
                    dispatch(removeActiveExperiment(updateResponse.data.id))
                }
            }
            return updateResponse.data;
        }).catch(console.warn);
}

export const createExperimentRequest = (token, data) => async dispatch => {
    return await ApiService.createExperiment(token, data).then(createResponse => {
        if (createResponse) {
            dispatch(updateExperiment(createResponse.data.id, createResponse.data))
            dispatch(updateActiveExperiment(createResponse.data.id, createResponse.data))
        }
        return createResponse.data
    }).catch(console.warn);
}

export const bulkCreateExperimentRequest = (token, data) => async dispatch => {
    return await ApiService.bulkCreateExperiment(token, data)
        .then(createResponse => {
            dispatch(getAllExperiments(token))
            return createResponse.data
        }).catch(console.warn);
}

export const getSingleExperimentRequest = (token, experimentId) => async () => {
    return await ApiService.getSingleExperiment(token, experimentId)
        .then(getResponse => getResponse.data)
        .catch(console.warn);
}

export const getResultDataRequest = (token, queryId, queryType, minCount, minReplicates) => async dispatch => {
    return await ApiService.getResultData(token, queryId, queryType, minCount, minReplicates)
        .then(getResponse => {
            if (getResponse) {
                dispatch(setSearchedExperiment(getResponse.data));
                return getResponse.data;
            }
        }).catch(console.warn);
}

export const getHeatmapData = (token, experimentId, minCount, minReplicates, foldChangeThreshold, maxLog10PValue, minUniquePeptides, cutoffShape) => async () => {
    return await ApiService.getHeatmapData(token, experimentId, minCount, minReplicates, foldChangeThreshold, maxLog10PValue, minUniquePeptides, cutoffShape)
        .then(getResponse => getResponse.data)
        .catch(console.warn);
}

export const getAllTreatments = (token) => async dispatch => {
    return await ApiService.getTreatments(token)
        .then(getResponse => {
            if (getResponse) {
                dispatch(setAllTreatments(getResponse.data));
            }
        }).catch(console.warn);
}
export const getExperimentCompounds = (token) => async dispatch => {
    return await ApiService.getExperimentCompounds(token)
        .then(getResponse => {
            if (getResponse) {
                dispatch(setExperimentCompounds(getResponse.data));
            }
        }).catch(console.warn);
}

export const getAllProteins = (token) => async dispatch => {
    return await ApiService.getProteins(token).then(getResponse => {
        if (getResponse) {
            dispatch(setAllProteins(getResponse.data));
        }
    }).catch(console.warn);
}

export const deleteExperimentRequest = (token, experimentId) => async dispatch => {
    return await ApiService.deleteExperiment(token, experimentId).then(deleteResponse => {
        dispatch(getAllExperiments(token));
        return deleteResponse.data;
    }).catch(console.warn);
}