import { BoxPlotTwoTone } from "@ant-design/icons";
import {Spin, Typography} from "antd";
import React, {useEffect} from "react";
import { connect } from 'react-redux'
import htmx from "htmx.org";

import {BACKEND_URL} from "../constants";

const { Text, Title } = Typography;

function PeptideBoxPlot({
    experimentId,
    minCount,
    minReplicates,
    normalized,
    peptideBoxPlot,
}) {
    const [geneId, setGeneId] = React.useState(-1);

    if (peptideBoxPlot.geneId !== undefined && peptideBoxPlot.geneId !== geneId) {
        setGeneId(peptideBoxPlot.geneId);
    }

    useEffect(() => {
        if (geneId !== -1) {
            htmx.trigger('#peptideBoxPlotRequestForm', 'changed');
        }
    }, [
        experimentId,
        geneId,
        minCount,
        minReplicates,
        normalized
    ])

    return <>
        <div
            style={{
                height: '450px',
                overflow: 'hidden',
                position: 'relative',
                border: '1px solid #ebedf0',
                borderRadius: 2,
                padding: '0em',
                textAlign: 'center',
                background: '#fafafa',
                marginBottom: '1em',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
            }}
        >
            <form id={'peptideBoxPlotRequestForm'}
                  style={{display: 'none'}}
                  hx-get={`${BACKEND_URL}/peptide_box_plot`}
                  hx-swap={'innerHTML'}
                  hx-target={'#peptideBoxPlot'}
                  hx-indicator={'#peptideBoxPlotLoadingIndicator'}
                  hx-trigger={'changed delay:0.66s'}
            >
                <input type={'hidden'}
                       name={'experimentId'}
                       value={experimentId}
                       readOnly
                />
                <input type={'hidden'}
                       name={'geneId'}
                       value={geneId}
                       readOnly
                />
                <input type={'hidden'}
                       name={'minCount'}
                       value={minCount}
                       readOnly
                />
                <input type={'hidden'}
                       name={'minReplicates'}
                       value={minReplicates}
                       readOnly
                />
                {
                    normalized ?
                        <input type={'hidden'}
                               name={'normalized'}
                               value={normalized}
                               readOnly
                        /> : <></>
                }
            </form>
            <div style={{
                height: '100%',
                alignContent: 'center',
                minWidth: '700px',
            }}>
                <div id={'peptideBoxPlot'}>
                    <Text type={'secondary'} strong>
                        <BoxPlotTwoTone style={{fontSize: '5em'}}/>
                    </Text>

                    <Title type={'secondary'} level={4} underline>
                        Hover over a point in scatter plot above to load related peptide box plot.
                    </Title>
                </div>

                <div id={'peptideBoxPlotLoadingIndicator'} className={'htmx-indicator'}>
                    <Text type={'secondary'} strong>
                        <BoxPlotTwoTone style={{fontSize: '5em'}}/>

                        <br/>

                        Generating box plot...
                    </Text>

                    <Spin style={{marginTop: '1em'}}/>
                </div>
            </div>
        </div>
    </>;
}

const mapStateToProps = ({peptideBoxPlot}) => {
    return {
        peptideBoxPlot,
    }
}

export default connect(mapStateToProps, {})(PeptideBoxPlot);