import { produce } from 'immer'
import {SET_GENE_ID} from "./peptideBoxPlotConstants";

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_GENE_ID:
            const { geneId } = action
            draft['geneId'] = geneId
            break

        default:
            break
        }

    return draft
})