import React, { useState } from 'react';
import _ from 'lodash'
import Plot from 'react-plotly.js';
import { Spin } from 'antd'
import { connect } from 'react-redux'
import { getResultDataRequest } from '../features/experiments/experimentsActions'
import { setLoading, } from '../features/results/resultsActions'
import { SMILESChemicalStructure } from "../shared/components";
import {useNavigate} from "react-router";
import {setGeneId} from "../features/peptideBoxPlot/peptideBoxPlotActions";

function Volcano({
    pageData,
    results,
    hits,
    volcanoUnique,
    volcanoFC,
    volcanoP,
    volcanoType,
    volcanoX,
    volcanoYMin,
    volcanoYMax,
    handleDataPointClicked,
    setGeneId,
 }) {
    const navigate = useNavigate();

    const [selectedChemicalStructureSVG, setSelectedChemicalStructureSVG] = useState('');

    const filterTitle = pageData.meta.filter_name;
    const filterLabel = pageData.meta.filter_label;
    let plotTitle = `${filterLabel ? filterLabel.split(';')[0] + " - (" + filterTitle + ")" : filterTitle}`;

    if ('compound' in pageData) {
        const experiment = pageData.compound.experiments.filter((experiment) => experiment.treatment_id === pageData.compound.id)[0];
        if (experiment !== undefined) {
            plotTitle += ` - ${experiment.name}`;
        }
    }


    const rawExperimentData = !filterLabel && volcanoUnique ? _.filter(pageData.data, obj => {
        return obj.unique_peptides >= volcanoUnique
    }) : pageData.data

    const experimentData = rawExperimentData.map(obj => {
        const newObject = { ...obj };
        if (newObject['gene_symbol'] !== null) {
            newObject['gene_symbol'] = newObject['gene_symbol'].split(';')[0];
        } else {
            newObject['gene_symbol'] = '';
        }
        return newObject;
    })

    const useDefault = _.isNil(volcanoFC) || _.isNil(volcanoP) || _.isNil(volcanoType)

    const defaultPiUp = !_.isEmpty(experimentData) ? (useDefault ? experimentData[0].default_up : Math.log2(volcanoFC) * volcanoP) : 0
    const defaultPiDown = !_.isEmpty(experimentData) ? (useDefault ? experimentData[0].default_down : Math.log2(volcanoFC) * volcanoP * -1) : 0

    const logPValues = _.map(experimentData, 'neg_log10_p_value')
    const logFCValues = _.map(experimentData, 'log_fc')

    const colors = filterLabel ? _.map(experimentData, () => 'blue') : _.map(hits, (item => {
        return item ? 'red' : 'blue'
    }))

    const labels = filterLabel ? _.map(experimentData, () => '') : _.map(hits, ((item, index) => {
        return item && experimentData[index] ? experimentData[index].gene_symbol : ''
    }))

    const minX = _.min(logPValues) - 0.25
    const maxXDefault = _.max(logPValues) + 0.25

    const minY = _.min(logFCValues) - 0.5
    const maxY = _.max(logFCValues) + 1.5

    const maxX = 0
    const xList = volcanoType === 'square' ? _.range(minX, 0.5, 0.1) : _.range(minX, maxX, 0.1)
    const yList = _.range(minY, maxY, 0.1)

    const yUpList = _.map(xList, x => volcanoType === 'square' ? Math.log2(volcanoFC) : -defaultPiUp / x)
    const yDownList = _.map(xList, x => volcanoType === 'square' ? -1 * Math.log2(volcanoFC) : -defaultPiDown / x)

    const xZoom = _.isNil(volcanoX) ? maxXDefault : volcanoX

    const yZoomMin = _.isNil(volcanoYMin) ? minY : volcanoYMin
    const yZoomMax = _.isNil(volcanoYMax) ? maxY : volcanoYMax

    const squareFinal = !filterLabel && volcanoType === 'square' ? {
        type: 'line',
        mode: 'lines',
        x: _.map(yList, y => volcanoP),
        y: yList,
        line: {
            shape: 'spline',
            dash: 'dashdot',
            color: 'gray',
            width: 2,
        },
        connectgaps: true,
        hoverinfo: 'none'
    } : {}

    if (_.isNil(experimentData.length)) {
        return <Spin style={{marginTop: 10}}/>
    }

    const data = [
        {
            name: '',
            type: 'scatter',
            mode: results.showLabel ? 'markers+text' : 'markers',
            text: labels,
            textposition: 'top center',
            x: logPValues,
            y: logFCValues,
            marker: {
                color: colors,
                opacity: 0.5,
                size: 6,
                line: {
                    color: ['black']
                }
            },
            hovertemplate: '<b>Log<sub>2</sub>FC:</b> %{y}<br />' +
                '<b>Log<sub>10</sub>P-Value:</b> %{x}<br />' +
                '<b>Treatment:</b> %{customdata.treatment_name}<br />' +
                '<b>Compound:</b> %{customdata.compound_name}<br />' +
                '<b>Accession:</b> %{customdata.accession_name}<br />' +
                '<b>Gene:</b> %{customdata.gene_symbol}<br />' +
                '<b>Unique Peptides:</b> %{customdata.unique_peptides}<br />' +
                '<b>Experiment Type:</b> %{customdata.experiment_type}' +
                `${pageData.meta.filter_label ? '<br /><b>Cell Line:</b> %{customdata.cell_line}' : ''}` +
                `${pageData.meta.filter_label ? '<br /><b>Experiment:</b> %{customdata.experiment_name}' : ''}`,
            customdata: experimentData,
            hoverlabel: {
                bgcolor: "white",
                font_size: 16,
                font_family: "Rockwell"
            }
        },
        filterLabel ? {} : {
            type: 'line',
            mode: 'lines',
            x: xList,
            y: yUpList,
            line: {
                shape: 'spline',
                dash: 'dashdot',
                color: 'gray',
                width: 2,
            },
            connectgaps: true,
            hoverinfo: 'none'
        },
        filterLabel ? {} : {
            type: 'line',
            mode: 'lines',
            x: xList,
            y: yDownList,
            line: {
                shape: 'spline',
                dash: 'dashdot',
                color: 'gray',
                width: 2,
            },
            connectgaps: true,
            hoverinfo: 'none'
        },
        squareFinal,
    ]



    return <>
        <Plot
            config={{
                displaylogo: false,
                responsive: true,
                toImageButtonOptions: {
                    format: 'svg',
                    filename: plotTitle
                }
            }}
            onClick={(e) => {
                const data = _.filter(e.points, element => element.data.type === 'scatter')[0];

                let url;
                if (filterLabel === '') {
                    url = `/plots/protein/${data.customdata.accession_id}`;
                } else {
                    url = `/plots/treatment/${data.customdata.treatment_id}`;
                }

                handleDataPointClicked(url);
            }}
            data={data}
            layout={{
                hovermode: 'closest',
                title: plotTitle,
                autosize: true,
                showlegend: false,
                xaxis: {
                    title: `Log<sub>10</sub> P Value`,
                    range: [minX, xZoom],
                },
                yaxis: {
                    title: `Log<sub>2</sub> FC Value`,
                    range: [yZoomMin, yZoomMax],

                }
            }}
            useResizeHandler={true}
            style={{width: !filterLabel ? '100%' : '75%', display: 'inline-block'}}
            onHover={(data) => {
                if (filterLabel) {
                    setSelectedChemicalStructureSVG(data.points[0].customdata.chemicalStructureSVG);
                } else if (data.points[0].customdata) {
                    setGeneId(data.points[0].customdata.gene_id);
                }
            }}
            onUnhover={() => setSelectedChemicalStructureSVG('')}
        />
        {filterLabel ?
            selectedChemicalStructureSVG ?
                <div style={{width: '25%', display: 'inline-block'}}>
                    <SMILESChemicalStructure chemicalStructureSVG={selectedChemicalStructureSVG}/>
                </div>
                : <></>
            : <></>}
    </>
}

const mapStateToProps = ({experiments, results}) => {
    return {
        experiments,
        results,
    }
}

export default connect(mapStateToProps, {
    getResultDataRequest,
    setLoading,
    setGeneId,
})(Volcano)